@import "~bootstrap/scss/bootstrap.scss";
@import url('https://fonts.googleapis.com/css2?family=Dongle&family=Lexend:wght@500&family=Oswald:wght@600&family=Playfair+Display:ital@1&family=Raleway:wght@300&family=Work+Sans:wght@100&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");


::-webkit-scrollbar {
  width: 0px;
}

.nav {
  position: sticky;
  display: flex;
  flex-wrap: wrap;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.profile{
  width: 15vmax;
  min-width: 15vh;
}

.img-about{
  width: 20vmax;
  min-width: 15vh;
}

.name{
  font-family: 'Oswald', sans-serif;
}

.text-grad{
  background-clip: text;
  background-image: linear-gradient(135deg, #1ddde8, #dd00f3, #e3e81d);
  opacity: 90%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 1800% 1800%;
  animation: rainbow 40s ease infinite;
}

.tagline{
  font-family: 'Playfair Display', serif;
  opacity: 90%;
}

.bg-grad{
  background-image: url(./pic/geo.png);
  background-repeat: repeat;
  animation: rainbow 500s linear infinite;
}

.bg-geo{
  background-image: linear-gradient(135deg, rgba(255, 255, 255, 0), #1ddee82d, #df00f36c, #e5e81d4b,  rgba(255, 255, 255, 0));
  background-size: 1000%;
  animation: rainbow 200s linear infinite;
}


.bg-col{
  background-image: linear-gradient(135deg, #1ddee825, #df00f31e, #e5e81d23);
  background-size: 1800% 1800%;
  animation: rainbow 40s ease infinite;
}

.conth{
  min-height: 85vh;
}

@keyframes rainbow {
  0% {
    background-position: 0% 82%
  }

  50% {
    background-position: 100% 18%
  }

  100% {
    background-position: 0% 82%
  }
}

.imgf{
  object-fit: contain;
  width: 100%;
  height: 50vh;
}

.imgi{
  min-width: 100px;
  background-color: white;
}

.imgi:hover{
  opacity: .5;
}

.bg-dark-op{
  background-color: rgba(0, 0, 0, 0.05);
}
